/**
 */
let PropertyValidator = function (app) {

    let result = {
        valid:  true,
        errors: [],
    };


    this.validate = (type, object) => {
        if (!app.data.properties || !_.isObject(app.data.properties[type])) {
            result.errors.push({msg: 'app.properties empty or ' + type + ' not found in'});
            return this.prepareResult();
        }
        if (!_.isObject(object)) {
            result.errors.push({msg: 'data is empty or not object'});
            return this.prepareResult();
        }
        let props = app.data.properties[type];
        Object.keys(props).forEach((key) => {
            object[key] = this.validateScalar(props[key], object[key], [key]);
        });
        return this.prepareResult();
    };

    this.validateScalar = (prop, value, keyPath) => {
        let valueO = {v: value};
        if (!this.validateRequired(prop, valueO, keyPath)) {
            return valueO.v;
        }
        if (!this.validateDataType(prop, valueO.v, keyPath)) {
            return valueO.v;
        }
        if (prop.is_relation) {
            this.validateRelation(prop, value, keyPath);
            return valueO.v;
        }
        this.validateRange(prop, valueO.v, keyPath);
        this.validatePossibleValues(prop, valueO.v, keyPath);
        if (prop.data_type === 'object') {
            valueO.v = this.validateObject(prop, valueO.v, keyPath);
        } else if (prop.data_type === 'array') {
            valueO.v = this.validateArray(prop, valueO.v, keyPath);
        }
        return valueO.v;
    };

    this.validateRange = (prop, value, keyPath) => {
        if(!prop.validate_api && prop.validate_client){
            prop.validate_api = prop.validate_client;
            delete prop.validate_client;
        }
        if (!prop.validate_api || prop.data_type === 'boolean' || prop.data_type === 'object' || _.isNull(value)) {
            return;
        }
        let vApi   = prop.validate_api;
        let length = ['string', 'array'].indexOf(prop.data_type) === -1 ? value : value.length;
        switch (true) {
            case _.isNumber(vApi.gt) && !(length > vApi.gt):
                this.addError('gt', '%s is not greater than ' + vApi.gt, prop, value, keyPath);
                break;
            case _.isNumber(vApi.gteq) && !(length >= vApi.gteq):
                this.addError('gteq', '%s is not greater or equal than ' + vApi.gteq, prop, value, keyPath);
                break;
            case _.isNumber(vApi.lt) && !(length < vApi.lt):
                this.addError('lt', '%s is not less than ' + vApi.lt, prop, value, keyPath);
                break;
            case _.isNumber(vApi.lteq) && !(length <= vApi.lteq):
                this.addError('lteq', '%s is not less or equal than ' + vApi.lteq, prop, value, keyPath);
                break;
        }
    };

    this.validatePossibleValues = (prop, value, keyPath) => {
        if (!prop.possible_value || !prop.possible_value.length || (!prop.is_required && _.isNull(value))) {
            return;
        }
        let pv = prop.possible_value.find((pv) => { return pv.value === value});
        if (!pv) {
            this.addError('notPossible', '%s is not a possible value', prop, value, keyPath);
        }
    };

    this.validateRequired = (prop, valueO, keyPath) => {
        if (!prop.is_required) {
            if (_.isUndefined(valueO.v)) {
                valueO.v = prop.data_type === 'array' ? [] : null;
            }
            return true;
        }
        let value = valueO.v;
        let valid = true;
        if (_.isUndefined(value) || _.isNull(value)) {
            return valid = this.addError('required', '%s is required', prop, value, keyPath);
        }
        switch (true) {
            case prop.data_type === 'array' && !value.length:
            case prop.data_type === 'string' && !value.length:
            case prop.data_type === 'object' && (!value || !Object.keys(value).length):
            case ['float', 'integer', 'unixtime'].indexOf(prop.data_type) !== -1
            && (_.isNaN(value) || !_.isNumber(value)):
                valid = this.addError('required', '%s is required', prop, value, keyPath);
                break;
        }
        return valid;
    };

    this.validateDataType = (prop, value, keyPath) => {
        let valid = true;
        if (!prop.is_required && _.isNull(value)) {
            return valid;
        }
        switch (true) {
            case prop.data_type === 'array' && !_.isArray(value):
                valid = this.addError('dataType', '%s has invalid data-type expect array but got ' + typeof value, prop, value, keyPath);
                break;
            case prop.data_type === 'object' && !_.isObject(value):
                valid = this.addError('dataType', '%s has invalid data-type expect object but got ' + typeof value, prop, value, keyPath);
                break;
            case (prop.data_type === 'float' || prop.data_type === 'unixtime') && (_.isNaN(value) || !_.isNumber(value)):
                valid = this.addError('dataType', '%s has invalid data-type expect float but got ' + typeof value, prop, value, keyPath);
                break;
            case prop.data_type === 'integer' && (_.isNaN(value) || !_.isNumber(value) || value % 1 !== 0):
                valid = this.addError('dataType', '%s has invalid data-type expect integer but got ' + typeof value, prop, value, keyPath);
                break;
            case prop.data_type === 'string' && (!_.isNumber(value) && !_.isString(value)):
                valid = this.addError('dataType', '%s has invalid data-type expect string but got ' + typeof value, prop, value, keyPath);
                break;
            case prop.data_type === 'boolean' && [0, 1, true, false].indexOf(value) === -1:
                valid = this.addError('dataType', '%s has invalid data-type expect boolean but got ' + typeof value, prop, value, keyPath);
                break;
        }
        return valid;
    };

    this.validateArray = (prop, value, keyPath) => {
        if (!prop.is_required && (!value || !value.length)) {
            return value;
        }
        value.forEach((v, i) => {
            let newKeyPath = keyPath.slice(0);
            newKeyPath.push(i);
            value[i] = this.validateScalar(prop.structure, v, newKeyPath);
        });
        return value;
    };

    this.validateObject = (prop, value, keyPath) => {
        if (!prop.is_required && _.isNull(value) || !prop.attributes) {
            return value;
        }
        Object.keys(prop.attributes).forEach((key) => {
            let newKeyPath = keyPath.slice(0);
            newKeyPath.push(key);
            if (!prop.attributes[key]) {
                return;
            }
            value[key] = this.validateScalar(prop.attributes[key], value[key], newKeyPath);
        });
        return value;
    };


    this.validateRelation = (prop, value, keyPath) => {
        if (!prop.is_required) {
            if (prop.cardinality === 'to_one' && (_.isNull(value) || _.isUndefined(value))) {
                return
            } else if (prop.cardinality === 'to_many' &&
                ((_.isNull(value) || _.isUndefined(value)) || (_.isArray(value) && !value.length))) {
                return
            }
        }
        let objectIds = [];
        value         = prop.cardinality === 'to_one' ? [value] : value;
        value.forEach((item, i) => {
            let newKeyPath = prop.cardinality === 'to_many' ? keyPath.slice(0) : keyPath;
            if (!item) {
                this.addError('required', '%s is required ', prop, item, newKeyPath);
                return;
            }
            let id = item.object_id;
            if (_.isUndefined(id) || _.isNaN(id) || !_.isNumber(id) || id % 1 !== 0) {
                if (prop.cardinality === 'to_many') {
                    newKeyPath.push(i);
                }
                this.addError('required', '%s is not valid ', prop, item, newKeyPath);
                return;
            }
            if (id && prop.unique && objectIds.indexOf(id) !== -1) {
                if (prop.cardinality === 'to_many') {
                    newKeyPath.push(i);
                }
                this.addError('unique', '%s is not unique ', prop, item, newKeyPath);
            }
            objectIds.push(id);
        });
    };


    this.prepareResult = () => {
        result.valid = !result.errors.length;
        return result;
    };

    this.addError = (type, message, prop, value, keyPath) => {
        let desc = prop.title ? prop.title : prop.description ? prop.description : keyPath.join('.');
        result.errors.push({
            msg:     $.sprintf(message, desc),
            keypath: keyPath.join('.'),
            prop:    prop,
            value:   value,
            type:    type,
        });
        return false;
    };
};